<template>
  <section class="section">
    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <div class="container">
          <b-steps :has-navigation="false" v-model="stepIndex">
            <b-step-item icon="fas fa-user">
              <form class="container" @submit.prevent="onSignUp">
                <h1 class="title">Create account</h1>
                <p class="subtitle">
                  Already have an account?
                  <a href="https://portal.powerquad.co.uk">Log in</a>
                  <!-- <router-link to="/login">Log in</router-link> -->
                </p>
                <div class="field">
                  <p class="control has-icons-left">
                    <input
                      v-model="email"
                      class="input"
                      placeholder="Email"
                      type="email"
                    />
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </p>
                </div>
                <div class="field">
                  <p class="control has-icons-left">
                    <input
                      v-model="password"
                      class="input"
                      placeholder="Password"
                      type="password"
                      v-on:input="enforcePasswordPolicy"
                    />
                    <span class="icon is-small is-left">
                      <i class="fas fa-lock"></i>
                    </span>
                  </p>
                </div>
                <div class="field">
                  <p class="control has-icons-left">
                    <input
                      v-model="confirmPassword"
                      class="input"
                      placeholder="Confirm password"
                      type="password"
                    />
                    <span class="icon is-small is-left">
                      <i class="fas fa-lock"></i>
                    </span>
                  </p>
                </div>
                <p id="passwordField"></p>
                <div v-if="isError">
                  <b-notification
                    type="is-danger is-light"
                    aria-close-label="Close notification"
                    role="alert"
                  >
                    {{ errorMessage }}
                  </b-notification>
                </div>
                <div class="container">
                  <button class="button is-primary is-xl my-3" type="submit">
                    Sign up
                  </button>
                </div>
              </form>
            </b-step-item>
            <b-step-item icon="fas fa-fingerprint">
              <b-notification v-if="isUnconfirmed" class="is-primary">
                <p>
                  We noticed you didn't finish signing up. We've resent the
                  confirmation code to finish creating your account.
                </p>
              </b-notification>
              <h1 class="title">Confirm account</h1>
              <p class="subtitle">
                Enter the confirmation code we sent to your email address to
                confirm your account.
              </p>

              <p>
                Didn't recieve the code?
                <a @click="resendConfirmationCode">Request another</a>
              </p>
              <form class="container" @submit.prevent="onConfirm">
                <div class="field">
                  <p class="control">
                    <input
                      v-model="confirmationCode"
                      class="input"
                      placeholder="Confirmation code"
                      type="numeric"
                    />
                  </p>
                </div>
                <div v-if="isError">
                  <b-notification
                    type="is-danger is-light"
                    aria-close-label="Close notification"
                    role="alert"
                  >
                    {{ errorMessage }}
                  </b-notification>
                </div>
                <div class="container">
                  <button class="button is-primary is-xl my-3" type="submit">
                    Confirm
                  </button>
                </div>
              </form>
            </b-step-item>
            <b-step-item icon="check">
              <h1 class="title">Sign up successful</h1>
              <p>
                Thank you for making an account! You should now be able to
                <a href="https://portal.powerquad.co.uk">log in</a>
                <!-- <router-link to="/login">log in</router-link> -->
              </p>
            </b-step-item>
          </b-steps>
        </div>
      </div>
      <div class="column"></div>
    </div>
    <b-loading v-model="isLoading"></b-loading>
  </section>
</template>

<script>
import { Auth } from "../../node_modules/aws-amplify";
export default {
  name: "Signup",
  data() {
    return {
      email: null,
      password: null,
      confirmPassword: null,
      confirmationCode: null,
      isError: false,
      isLoading: false,
      isUnconfirmed: false,
      errorMessage: null,
      passwordMessage: "",
      stepIndex: 0,
    };
  },
  methods: {
    async onSignUp() {
      this.setLoading(true);
      try {
        const username = this.email;
        const password = this.password;
        const obj = {
          username: username,
          password: password,
          attributes: {
            email: username,
          },
        };
        if (this.validatePassword()) {
          await Auth.signUp(obj);
          this.setLoading(false);
          this.errorMessage = null;
          this.isError = false;
          this.stepIndex = 1;
        }
      } catch (e) {
        this.errorMessage = e.message;
        this.isError = true;
      }
      this.setLoading(false);
    },
    async resendConfirmationCode() {
      try {
        if (this.isUnconfirmed) {
          await Auth.resendSignUp(this.$route.params.email);
        } else {
          await Auth.resendSignUp(this.username);
        }
      } catch (e) {
        this.showError(e.message);
      }
    },
    enforcePasswordPolicy() {
      this.passwordMessage = `
      ${
        this.password.match(".{8,}") ? "✅" : "❌"
      } At least 8 characters long <br>
      ${
        this.password.match("(?=.*?[A-Z])") ? "✅" : "❌"
      } Contains uppercase letters <br>
      ${
        this.password.match("(?=.*?[a-z])") ? "✅" : "❌"
      } Contains lowercase letters <br>
      ${
        this.password.match("(?=.*?[0-9])") ? "✅" : "❌"
      } At least one digit <br>
      ${
        this.password.match("(?=.*?[#?!@$%^&*-])") ? "✅" : "❌"
      } At least one symbol character <br>`;

      document.getElementById("passwordField").innerHTML = this.passwordMessage;
    },
    async onConfirm() {
      if (this.confirmationCode == "") {
        this.showError("The confirmation code cannot be empty");
      } else {
        try {
          if (this.isUnconfirmed) {
            await Auth.confirmSignUp(
              this.$route.params.email,
              this.confirmationCode
            );
          } else {
            await Auth.confirmSignUp(this.email, this.confirmationCode);
          }
          this.stepIndex = 2;
        } catch (e) {
          this.showError(e.message);
        }
      }
    },
    showError(message) {
      this.isError = true;
      this.isLoading = false;
      this.errorMessage = message;
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
    validatePassword() {
      const isValid = this.password == this.confirmPassword;
      if (!isValid) {
        this.errorMessage = "Those passwords don't match. Try again";
        this.isError = true;
      }
      return isValid;
    },
  },
  mounted() {
    if (
      this.$route.params != null &&
      this.$route.params.email != null &&
      this.$route.params.isUnconfirmed == true
    ) {
      this.stepIndex = 1;
      this.isUnconfirmed = true;
      this.resendConfirmationCode();
    }
  },
  computed: {},
};
</script>
