import Vue from "vue";
//import store from '@/store'
import VueRouter from "vue-router";
import Signup from "../views/Signup.vue";
import { isLoggedIn } from "../session";
//import { Auth } from "../../node_modules/aws-amplify";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "Signup",
    component: Signup,
    meta: {
      title: 'powerQuad Web | Dashboard'
    }
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
      meta: {
        title: 'powerQuad Web | Login'
      }
      
  },
  {
    path: "/signup",
    name: "Signup",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Signup.vue"),
      meta: {
        title: 'powerQuad Web | Sign Up'
      }
  },
  {
    path: "/password_reset",
    name: "ForgotPassword",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/ForgotPassword.vue"),
      meta: {
        title: 'powerQuad Web | Forgot Password'
      }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Settings.vue"),
      meta: {
        title: 'powerQuad Web | Settings'
      }
  },
  {
    path: "/add",
    name: "AddUnit",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/AddUnit.vue"),
      meta: {
        title: 'powerQuad Web | Add Unit'
      }
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach(async (to, from, next) => {
  // let isExpired = store.getters.getIsExpired;
  // if (to.name === "Home" && isExpired) next({ name: "Login" });
  // else if (to.name === "Login" && !isExpired) next({ name: "Home" });
  // else next();
  document.title = to.meta.title || 'powerQuad Web'; //set page title

  const loggedIn = await isLoggedIn();
  if (to.name === "Home" && !loggedIn) next({ name: "Login" });
  else if (to.name === "Login" && loggedIn) next({ name: "Home" });
  else next();
});

router.afterEach(() => {
  document.activeElement.blur(); //remove focus ring after navigtion
})

export default router;
