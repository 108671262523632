<template>
    <b-navbar class="container mb-5">
        <template #brand>
          <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <img src="../assets/logo_cl.png" alt="Logo" />
          </b-navbar-item>
        </template>
  
        <template #end>
                    
          <b-navbar-item tag="div">
              <div v-show="isAuth" class="buttons">
                 <b-button type="is-ghost" icon-left="tachometer-alt" tag="router-link" to="/">Dashboard</b-button>
                 <!-- <b-button type="is-ghost" icon-left="cloud-upload-alt">Carbon intensity</b-button>
                 <b-button type="is-ghost" icon-left="heartbeat">Battery health</b-button>
                 <b-button type="is-ghost" icon-left="cog" tag="router-link" to="/settings">Settings</b-button> -->
                 <b-button type="is-ghost" icon-left="sign-out-alt" @click="logout">Logout</b-button>                 
              </div>
          </b-navbar-item>
         
        </template>
    </b-navbar>
</template>

<script>
import { Auth } from "../../node_modules/aws-amplify";
export default {
  name: 'NavBar',
  props: {},
  data: function() {
    return {
      open: false
    }
  },
  methods: {
    async logout() {
      try {
        await Auth.signOut();
        this.$store.commit("SET_AUTH", false);
        this.$router.push({ name: "Login" });
        this.open = false;
      } catch (e) {
        console.log(e);
      }
    },
    showToken () {
      this.$buefy.snackbar.open({ 
            message: this.$store.getters.getToken,
            position: 'is-top',
            duration: 4000})
    }
  },
  computed: {
    isAuth() {
      return this.$store.getters.getIsAuth;
    }
  }
}
</script>

<style scoped>
.button.is-ghost.router-link-exact-active {
  border-color: rgb(232, 232, 232);
}
.button.is-ghost:focus {
  border-color: rgb(232, 232, 232);
}
.button.is-ghost {
  color: black;
}
</style>
