<template>
  <div id="app">
    <div id="nav">
      <NavBar />
    </div>
    <router-view/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';

export default {
  components: {
    NavBar
  },
  data(){
    return {
    }
  },
  // created () {
  //   axios.interceptors.response.use(undefined, function (err) {
  //     return new Promise(function (resolve, reject) {
  //       if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
  //         this.router.push({ path: 'login' });
  //       }
  //       throw err;
  //     });
  //   });

  //   axios.interceptors.response.use((config) => {
  //     if (this.$store.getters.getIsExpired) {
  //       this.router.push({ path: 'login' })
  //     }
  //     return config;
  //   }, (error) => {
  //     return Promise.reject(error);
  //   });
  // }
  created (){
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          this.router.push({ path: 'login' })
          //  2. Reset authentication from localstorage/sessionstorage
        }
        return Promise.reject(error);        
      }
    );
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #9c9c9c;
}
#app, html, body {
  height: 100%;
}

html { overflow-y: auto !important}
/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>

<style scoped>
.is-primary {
  background-color: #336699;
}
</style>

<style lang="scss">

//$family-sans-serif: "Nunito", sans-serif;

@import "@/assets/_variables.scss"; //sometimes needs this, not sure why could be Vue Cli version?
@import "~bulma";

</style>