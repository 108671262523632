import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const state = {
  auth: false,
  token: "",
  refresh: "",
  expires: new Date(),
  uid: "",
  userSettings: "",
};

const getters = {
  getIsAuth: (state) => state.auth,
  getToken: (state) => state.token,
  getRefresh: (state) => state.refresh,
  getExpires: (state) => state.expires,
  getIsExpired: (state) =>
    new Date(state.expires).getTime() < new Date().getTime(),
  getUid: (state) => state.uid,
  getUserSettings: (state) => state.userSettings,
};

const actions = {};

const mutations = {
  SET_AUTH(state, auth) {
    state.auth = auth;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_REFRESH(state, refresh) {
    state.refresh = refresh;
  },
  SET_EXPIRES(state, expires) {
    state.expires = expires;
  },
  SET_LOGSPINNED(state, logsPinned) {
    state.logsPinned = logsPinned;
  },
  SET_UID(state, uid) {
    state.uid = uid;
  },
  SET_USER_SETTINGS(state, userSettings) {
    state.userSettings = userSettings;
  },
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins: [createPersistedState()],
});
