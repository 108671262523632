import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import router from './router'
import store from './store'
import VueApexCharts from 'vue-apexcharts'
import { Amplify } from "aws-amplify";
import { config } from "./config";

Vue.config.productionTip = false

Vue.use(Buefy, {
  defaultIconPack: "fas",
});

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
